.reviews-block {
    display: inline-block;
    width: 100%;
    padding: 30px 0 48px;
    &.margin-bottom {
        padding: 0;
        margin: 0 0 38px;
    }
    .reviews-block-holder {
        display: inline-block;
        width: 100%;
    }
    .common-actions {
        margin: 27px 0 0;
    }
}

.reviews { 
    display: inline-block;
    width: 100%;
	.reviews-holder { 
        display: inline-block;
        width: calc(100% + 10px);
        margin-left: -5px;
        &.slick-slider {
            .review { 
                width: 100%;
            }
        }
		.review { 
            float: left;
            width: 33.333%;
            box-sizing: border-box;
            padding: 0 5px;
            margin: 0 0 10px;
		}
	}
}

.review { 
    width: 100%;
    box-sizing: border-box;
    .review-holder { 
        display: inline-block;
        width: 100%;
        border-radius: 10px;
        background-color: #F9F9F9;
        box-sizing: border-box;
        padding: 25px;
        .author { 
            display: flex;
            align-items: center;
            margin: 0 0 15px;
            .image { 
                width: 65px;
                height: 65px;
                border-radius: 50%;
                line-height: 65px;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                text-align: center;
                color: white;
                background-color: $brand-primary;
                overflow: hidden;
                img { 
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text { 
                width: calc(100% - 65px);
                box-sizing: border-box;
                padding-left: 15px;
                .name { 
                    width: 100%;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0 0 5px;
                }

                .stars { 
                    width: 100%;
                    .stars-rating { 
                        width: 100%;
                        color: #EDEDED;
                        &.stars-5 {
                            .star {
                                color: $brand-primary;
                            }
                        }
                        &.stars-4 {
                            .star:nth-of-type(1), .star:nth-of-type(2), .star:nth-of-type(3), .star:nth-of-type(4) {
                                color: $brand-primary;
                            }
                        }
                        &.stars-3 {
                            .star:nth-of-type(1), .star:nth-of-type(2), .star:nth-of-type(3) {
                                color: $brand-primary;
                            }
                        }
                        &.stars-2 {
                            .star:nth-of-type(1), .star:nth-of-type(2) {
                                color: $brand-primary;
                            }
                        }
                        &.stars-1 {
                            .star:nth-of-type(1) {
                                color: $brand-primary;
                            }
                        }
                        .star { 
                            float: left;
                            margin-right: 3px;
                            svg { 
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }

        .review-body { 
            display: inline-block;
            width: 100%;
            &.with-video {
                .review-body-holder { 
                    .text {
                        -webkit-line-clamp: 5;
                        line-clamp: 5;
                        height: 100px;
                    }
                }
            }
            &.with-gallery {
                .review-body-holder { 
                    .text {
                        -webkit-line-clamp: 10;
                        line-clamp: 10;
                        height: 200px;
                    }
                }
            }
            .review-body-holder { 
                display: inline-block;
                width: 100%;
                height: 304px;
                overflow: hidden;
                .text { 
                    width: 100%;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 143%;
                    color: $gray-dark;
                    margin: 0 0 21px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 15;
                    -webkit-box-orient: vertical;
                    line-clamp: 15;
                    box-orient: vertical;
                }

                .video { 
                    display: inline-block;
                    width: 100%;
                    height: 185px;
                    a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        background-color: $gray-base;
                        &:hover {
                            &:after {
                                background-color: $brand-primary-accent;
                                transition: all 300ms;
                            }
                            img {
                                opacity: 1;
                                transition: all 300ms;
                            }
                        }
                        &:after {
                            content: "";
                            display: inline-block;
                            background-image: url(../img/play.svg);
                            background-position: 55% 50%;
                            background-size: 12px 20px;
                            background-repeat: no-repeat;
                            background-clip: border-box;
                            width: 45px;
                            height: 45px;
                            line-height: 45px;
                            background-color: $brand-primary;
                            border-radius: 50%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            transition: all 300ms;
                        }
                        img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            opacity: 0.7;
                            transition: all 300ms;
                        }
                    }
                }

                .gallery { 
                    width: calc(100% + 10px);
                    margin-left: -5px;
                    .image { 
                        width: 33.333%;
                        float: left;
                        box-sizing: border-box;
                        padding: 0 5px;
                        a { 
                            display: inline-block;
                            width: 100%;
                            height: 85px;
                            overflow: hidden;
                            border-radius: 5px;
                            &:hover {
                                img {
                                    transform: scale(1.1);
                                    transition: all 300ms;
                                }
                            }
                            img { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 300ms;
                            }
                        }
                    }
                }
            }

            .action { 
                display: inline-block;
                width: 100%;
                margin: 20px 0 0;
                a { 
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    color: $gray-dark;
                    &:hover {
                        color: $brand-primary;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/reviews";
@import "../../media/mobile/includes/index/reviews";
