@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .policy-block { 
        margin: 0 0 40px;
        .policy-block-holder { 
            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 143%;
                margin: 0 0 20px;
            }

            ul { 
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                li {
                    margin: 0 0 1px;
                }
            }
        }
    }
}

