@media (max-width: $screen-lg) {
    // lg-md view
    .quote-block { 
        .quote-block-holder { 
            .quote-text { 
                width: calc(100% - 471px);
            }

            .quote-image { 
                width: 471px;
            }
        }
    }

    .about-info-block { 
        margin: 0 0 48px;
        .about-info-block-holder { 
            .info { 
                width: calc(100% - 481px);
                .info-holder { 
                    .text-block { 
                        margin: 0 0 13px;
                    }
                }
            }

            .image { 
                width: 481px;
                .image-holder { 
                    padding: 56px 0 0;
                    img { 
                        
                    }
                }
            }
        }
    }

    .numbers-block { 
        .numbers-block-holder { 
            .heading { 

            }

            .numbers { 
                .numbers-holder { 
                    .number { 
                        .number-holder { 
                            padding: 23px 20px 23px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .quote-block { 
        .quote-block-holder {
            width: calc(100% + 10px);
            margin-left: -5px;
            .quote-text { 
                width: calc(100% - 335px);
                padding: 0 5px;
                .quote-text-holder { 
                    padding: 56px 20px 20px;
                    .quote-icon { 
                        left: 20px;
                        top: 20px;
                    }

                    .text { 
                        p {
                            font-size: 14px;
                            margin: 0 0 12px;
                        }
                    }

                    .author { 
                        font-size: 14px;
                    }
                }
            }

            .quote-image { 
                width: 335px;
                padding: 0 5px;
            }
        }
    }

    .about-info-block { 
        .about-info-block-holder { 
            width: 100%;
            margin: 0;
            flex-wrap: wrap;
            .info { 
                width: 100%;
                padding: 0;
                .info-holder { 
                    position: relative;
                    .text-block { 
                        .heading { 
                            h3, .h3 {
                                font-size: 22px;
                            }
                        }
                        .text {
                            p {
                                font-size: 14px;
                                margin: 0 0 17px;
                            }
                        }
                    }

                    .numbers-block { 
                        position: absolute;
                        top: 100%;
                        left: 412px;
                        width: calc(95.203vw - 412px);
                    }
                }
            }

            .image { 
                width: 100%;
                padding: 0;
                .image-holder { 
                    width: 392px;
                    padding: 0;
                }
            }
        }
    }
}

.numbers-block { 
    .numbers-block-holder { 
        .heading { 
            font-size: 16px;
        }

        .numbers { 
            .numbers-holder { 
                flex-direction: column;
                width: 100%;
                margin: 0;
                .number { 
                    width: 100%;
                    padding: 0;
                    margin: 0 0 14px;
                    .number-holder { 
                        .value {
                            font-size: 28px;
                        }

                        .text {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

