.quote-block { 
    display: inline-block;
    width: 100%;
	margin: 0 0 100px;
	.quote-block-holder { 
        display: flex;
        width: calc(100% + 20px);
		margin-left: -10px;
		.quote-text { 
            width: calc(100% - 560px);
            height: auto;
			box-sizing: border-box;
			padding: 0 10px;
			.quote-text-holder { 
                display: inline-block;
                width: 100%;
				box-sizing: border-box;
				padding: 71px 30px 30px;
				background: $bg-secondary;
				border-radius: 10px;
				position: relative;
				.quote-icon { 
					position: absolute;
					left: 30px;
					top: 30px;
					svg { 

					}
				}

				.text { 
					display: inline-block;
					width: 100%;
					p { 
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; 
						color: $gray-dark;
						margin: 0 0 14px;
					}
				}

				.author { 
					display: inline-block;
					width: 100%;
					margin: 9px 0 0;
					text-align: right;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}
		}

		.quote-image { 
            width: 560px;
            height: auto;
			box-sizing: border-box;
			padding: 0 10px;
			.quote-image-holder { 
                width: 100%;
				height: 100%;
				border-radius: 10px;
				overflow: hidden;
				img { 
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}

.about-info-block { 
	display: inline-block;
	width: 100%;
	margin: 0 0 46px;
	.about-info-block-holder { 
		display: flex;
		width: calc(100% + 30px);
		margin-left: -15px;
		.info { 
			width: calc(100% - 550px);
			box-sizing: border-box;
			padding: 0 15px;
			.info-holder { 
				display: inline-block;
				width: 100%;
				.text-block { 
					display: inline-block;
					width: 100%;
					margin: 0 0 55px;
					.heading { 
						display: inline-block;
						width: 100%;
						margin: 0 0 30px;
						h3, .h3 { 
							margin: 0;
							br { 

							}
						}
					}

					.text { 
						display: inline-block;
						width: 100%;
						p { 
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 140%;
							color: $gray-dark;
							margin: 0 0 14px;
						}
					}
				}
				
			}
		}

		.image { 
			width: 550px;
			box-sizing: border-box;
			padding: 0 15px;
			.image-holder { 
				display: inline-block;
				width: 100%;
				overflow: hidden;
				img { 
					display: inline-block;
					width: 100%;
					border-radius: 10px;
					height: auto;
				}
			}
		}
	}
}

.numbers-block { 
	display: inline-block;
	width: 100%;
	.numbers-block-holder { 
		display: inline-block;
		width: 100%;
		.heading { 
			display: inline-block;
			width: 100%;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0 0 15px;
		}

		.numbers { 
			display: inline-block;
			width: 100%;
			.numbers-holder { 
				display: flex;
				width: calc(100% + 20px);
				margin-left: -10px;
				.number { 
					width: 33.333%;
					box-sizing: border-box;
					padding: 0 10px;
					.number-holder { 
						border-radius: 10px;
						background: $bg-secondary;
						padding: 22px 25px 25px;
						.value { 
							font-size: 38px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							margin: 0 0 4px;
						}

						.text { 
							font-size: 18px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/about";
@import "../../media/mobile/includes/common/about";
