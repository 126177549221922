@media (max-width: $screen-sm) {
    // sm-view
    .not-found-block { 
        padding: 51px 0 66px;
        .not-found-block-holder {
            padding: 144px 0 0;
            .text { 
                .text-holder { 
                    h1, .h1 { 
                        margin: 0 0 20px;
                    }

                    p {
                        font-size: 12px;
                        margin: 0 0 16px;
                    }

                    .actions {
                        margin: 13px 0 0;
                        .action { 
                            a {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .image { 
                .image-holder { 
                    width: 290px;
                    height: 94.3px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
