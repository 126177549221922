.certs-block {
    display: inline-block;
    width: 100%;
    .certs-block-holder {
        display: inline-block;
        width: 100%;
        padding: 50px 0 100px;
    }
}

.certs-slider {
    display: inline-block;
    width: 100%;
    position: relative;
    .prev, .next {
        position: absolute;
        opacity: 0;
        z-index: -1;
        &.slick-arrow {
            opacity: 1;
            z-index: 1;
        }
    }
    .certs-slider-holder {
        width: calc(100% + 20px);
        margin-left: -10px;
        .cert-slide {
            width: 352px;
            box-sizing: border-box;
            padding: 0 10px;
        }
    }
}

.cert-slide {
    .cert-slide-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 400px;
        background: white;
        padding: 55px;
        box-sizing: border-box;
        a {
            display: inline-block;
            max-width: 100%;
            height: 100%;
            &:hover {
                img {
                    opacity: 0.7;
                    transition: all 300ms;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: all 300ms;
            }
        }
    }
}

@import "../../media/tablet/includes/common/certs";
@import "../../media/mobile/includes/common/certs";
