@media (max-width: $screen-lg) {
    // lg-md view
    .certs-block {
        .certs-block-holder {
            padding: 50px 0 80px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .certs-block {
        .certs-block-holder {
            padding: 50px 0 59px;
        }
    }

    .certs-slider {
        .certs-slider-holder {
            width: calc(100% + 10px);
            margin-left: -5px;
            .cert-slide {
                padding: 0 5px;
                width: 258px;
            }
        }
    }

    .cert-slide {
        .cert-slide-holder {
            height: 300px;
            padding: 25px;
        }
    }
}
