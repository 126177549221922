.not-found-block { 
    display: inline-block;
    width: 100%;
    padding: 141px 0 141px;
    overflow: hidden;
	.not-found-block-holder { 
        display: inline-block;
        width: 100%;
        position: relative;
		.text { 
            display: inline-block;
            width: 518px;
			.text-holder { 
                display: inline-block;
                width: 100%;
				h1, .h1 { 
                    margin: 0 0 16px;
				}

				p { 
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    color: $gray-dark;
                    margin: 0 0 14px;
				}

				.actions { 
                    display: inline-block;
                    width: 100%;
                    margin: 24px 0 0;
					.action { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 10px;
						a { 
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            color: $gray-base;
                            display: inline-block;
                            position: relative;
                            padding-right: 26px;
                            &:hover {
                                color: $brand-primary;
                            }
							.icon { 
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 16px;
                                height: 12px;
								svg { 
                                    width: 100%;
                                    height: 100%;
									path { 

									}
								}
							}
						}
					}
				}
			}
		}

		.image { 
            position: absolute;
            left: 548px;
            top: -9px;
			.image-holder { 
                width: 922px;
                height: 300px;
				img { 
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/404";
@import "../../media/mobile/includes/common/404";
