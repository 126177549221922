.promos-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 97px;
    .promos-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.promos-list {
    display: inline-block;
    width: 100%;
    .promos-list-holder {
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-auto-rows: 503px;
        gap: 20px 20px;
    }
}

.promos-slider {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: 97px;
    .promos-slider-holder {
        .promo {
            box-sizing: border-box;
            padding: 0 10px;
            height: 503px;
        }
    }
}

.promo { 
    display: inline-block;
    width: 100%;
    height: 100%;
	.promo-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
		a { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            color: white;
            border-radius: 10px;
            overflow: hidden;
            &:hover {
                .text { 
                    .desc { 
                        max-height: 500px;
                        transition: all 1000ms;
                    }
                }
            }
			.image { 
                position: relative;
                left: 0;
                top: 0;
                z-index: 1;
                display: inline-block;
                width: 100%;
                height: 100%;
                background: $gray-base;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.4;
				}
			}

			.text { 
                display: inline-block;
                width: 100%;
                position: absolute;
                z-index: 2;
                left: 0;
                bottom: 0;
                box-sizing: border-box;
                padding: 23px 25px;
				.name { 
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    box-orient: vertical;
                    width: 100%;
                    height: 66px;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
				}

				.desc { 
                    float: left;
                    width: 100%;
                    max-height: 0;
                    overflow: hidden;
                    transition: all 500ms;
					.desc-holder { 
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -moz-box;
                        -moz-box-orient: vertical;
                        display: -webkit-box;
                        -webkit-line-clamp: 15;
                        -webkit-box-orient: vertical;
                        line-clamp: 15;
                        box-orient: vertical;
                        width: 100%;
                        margin: 15px 0 0;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/promos";
@import "../../media/mobile/includes/common/promos";
