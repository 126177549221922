@media (max-width: $screen-lg) {
    // lg-md view
    .promos-block {
        margin: 0 0 79px;
    }

    .promos-list {
        .promos-list-holder {
            grid-auto-rows: 390px;
        }
    }

    .promos-slider {
        .promos-slider-holder {
            .promo {
                height: 390px;
            }
        }
    }

    .promo { 
        .promo-holder { 
            a {
                .text { 
                    .desc { 
                        .desc-holder { 
                            -webkit-line-clamp: 12;
                            line-clamp: 12;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .promos-block {
        margin: 0 0 58px;
    }

    .promos-list {
        .promos-list-holder {
            grid-auto-rows: 290px;
            gap: 10px 10px;
        }
    }

    .promos-slider {
        width: calc(100% + 10px);
        margin-left: -5px;
        margin-bottom: 57px;
        .promos-slider-holder {
            .promo {
                padding: 0 5px;
                height: 290px;
            }
        }
    }

    .promo { 
        .promo-holder { 
            a {
                .image { 
                    img { 
                        opacity: 0.6;
                    }
                }

                .text { 
                    padding: 20px 20px;
                    .name {
                        font-size: 20px;
                        height: 54px;
                    }
                    .desc { 
                        max-height: 500px;
                        .desc-holder { 
                            -webkit-line-clamp: 9;
                            line-clamp: 9;
                            font-size: 14px;
                            margin: 17px 0 0;
                            
                        }
                    }
                }
            }
        }
    }
}
