@charset "UTF-8";
.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 21px;
  margin-bottom: 19px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  margin-right: 4px;
  color: #121214;
  margin-bottom: 5px;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #D9D9D9;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #fad379;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep {
  position: relative;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep:after {
  content: "";
  display: inline-block;
  width: 0px;
  height: 14px;
  border-right: 1px solid #D9D9D9;
  position: absolute;
  left: 0;
  top: 5px;
}

@media (max-width: 1499px) {
  .breadcrumbs {
    margin-top: 17px;
    margin-bottom: 17px;
  }
}
@media (max-width: 1025px) {
  .breadcrumbs {
    margin-top: 2px;
    margin-bottom: 10px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 14px;
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 12px;
    margin-right: 3px;
  }
}
.pagination ul {
  display: inline-block;
  list-style: none;
  margin: 0 -2.5px;
}
.pagination ul > li {
  float: left;
  margin: 0 2.5px;
}
.pagination ul > li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid #F9F9F9;
  background-color: #F9F9F9;
  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121214;
  text-decoration: none;
  border-radius: 3px;
  transition: all 300ms;
}
.pagination ul > li a:hover {
  border-color: #121214;
  background-color: #121214;
  color: white;
  transition: all 300ms;
}
.pagination ul > li.current a {
  pointer-events: none;
  touch-action: none;
  border-color: #121214;
  background-color: #121214;
  color: white;
  transition: all 300ms;
}
.pagination ul > li.middle a {
  color: #121214;
  background: none;
  border: none;
  width: auto;
  align-items: flex-end;
}
.pagination ul > li.middle a:hover {
  color: #F7B316;
  transition: all 300ms;
}
.pagination ul > li.next, .pagination ul > li.prev {
  margin: 0 3.5px;
}
.pagination ul > li.next.disabled a, .pagination ul > li.prev.disabled a {
  color: #EDEDED;
  pointer-events: none;
  touch-action: none;
}
.pagination ul > li.next a, .pagination ul > li.prev a {
  color: #121214;
  background: none;
  border: none;
  width: auto;
}
.pagination ul > li.next a svg, .pagination ul > li.prev a svg {
  width: 8px;
  height: 14px;
}
.pagination ul > li.next a:hover, .pagination ul > li.prev a:hover {
  color: #F7B316;
  transition: all 300ms;
}
.pagination ul > li.next a svg {
  transform: rotate(180deg);
}

@media (max-width: 1025px) {
  .pagination ul > li a {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .pagination ul > li a {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  .pagination ul > li.next a svg, .pagination ul > li.prev a svg {
    width: 6px;
    height: 10px;
  }
}
.common-heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-heading .common-heading-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #EDEDED;
  padding: 0 0 20px;
}
.common-heading .common-heading-holder .text h1, .common-heading .common-heading-holder .text .h1 {
  margin: 0;
}
.common-heading .common-heading-holder .action {
  padding: 0 0 3px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.common-heading .common-heading-holder .action a:hover {
  color: #F7B316;
}
.common-heading .common-heading-holder .action a .text {
  float: left;
}
.common-heading .common-heading-holder .action a .icon {
  float: left;
  width: 16px;
  height: 12px;
  margin-left: 10px;
  padding-top: 2px;
}
.common-heading .common-heading-holder .action a .icon svg {
  width: 100%;
  height: 100%;
}

.common-action {
  display: inline-block;
  width: 100%;
  margin: 13px 0 0;
}
.common-action .common-action-holder {
  display: flex;
  justify-content: center;
}
.common-action .common-action-holder .btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  width: 290px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #7A7A7A;
  border-color: #7A7A7A;
  background: white;
  border-radius: 8px;
  padding: 0;
}
.common-action .common-action-holder .btn .text {
  float: left;
}
.common-action .common-action-holder .btn .icon {
  float: left;
  margin-left: 10px;
}

@media (max-width: 1025px) {
  .common-heading .common-heading-holder .action {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .common-heading {
    margin: 0 0 25px;
  }
  .common-heading .common-heading-holder {
    padding: 0 0 9px;
  }
}
.reviews-block {
  display: inline-block;
  width: 100%;
  padding: 30px 0 48px;
}
.reviews-block.margin-bottom {
  padding: 0;
  margin: 0 0 38px;
}
.reviews-block .reviews-block-holder {
  display: inline-block;
  width: 100%;
}
.reviews-block .common-actions {
  margin: 27px 0 0;
}

.reviews {
  display: inline-block;
  width: 100%;
}
.reviews .reviews-holder {
  display: inline-block;
  width: calc(100% + 10px);
  margin-left: -5px;
}
.reviews .reviews-holder.slick-slider .review {
  width: 100%;
}
.reviews .reviews-holder .review {
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 0 0 10px;
}

.review {
  width: 100%;
  box-sizing: border-box;
}
.review .review-holder {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background-color: #F9F9F9;
  box-sizing: border-box;
  padding: 25px;
}
.review .review-holder .author {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}
.review .review-holder .author .image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  line-height: 65px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: white;
  background-color: #F7B316;
  overflow: hidden;
}
.review .review-holder .author .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review .review-holder .author .text {
  width: calc(100% - 65px);
  box-sizing: border-box;
  padding-left: 15px;
}
.review .review-holder .author .text .name {
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 5px;
}
.review .review-holder .author .text .stars {
  width: 100%;
}
.review .review-holder .author .text .stars .stars-rating {
  width: 100%;
  color: #EDEDED;
}
.review .review-holder .author .text .stars .stars-rating.stars-5 .star {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(1), .review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(2), .review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(3), .review .review-holder .author .text .stars .stars-rating.stars-4 .star:nth-of-type(4) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-3 .star:nth-of-type(1), .review .review-holder .author .text .stars .stars-rating.stars-3 .star:nth-of-type(2), .review .review-holder .author .text .stars .stars-rating.stars-3 .star:nth-of-type(3) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-2 .star:nth-of-type(1), .review .review-holder .author .text .stars .stars-rating.stars-2 .star:nth-of-type(2) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating.stars-1 .star:nth-of-type(1) {
  color: #F7B316;
}
.review .review-holder .author .text .stars .stars-rating .star {
  float: left;
  margin-right: 3px;
}
.review .review-holder .author .text .stars .stars-rating .star svg {
  width: 18px;
  height: 18px;
}
.review .review-holder .review-body {
  display: inline-block;
  width: 100%;
}
.review .review-holder .review-body.with-video .review-body-holder .text {
  -webkit-line-clamp: 5;
  line-clamp: 5;
  height: 100px;
}
.review .review-holder .review-body.with-gallery .review-body-holder .text {
  -webkit-line-clamp: 10;
  line-clamp: 10;
  height: 200px;
}
.review .review-holder .review-body .review-body-holder {
  display: inline-block;
  width: 100%;
  height: 304px;
  overflow: hidden;
}
.review .review-holder .review-body .review-body-holder .text {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  color: #7A7A7A;
  margin: 0 0 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  line-clamp: 15;
  box-orient: vertical;
}
.review .review-holder .review-body .review-body-holder .video {
  display: inline-block;
  width: 100%;
  height: 185px;
}
.review .review-holder .review-body .review-body-holder .video a {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #121214;
}
.review .review-holder .review-body .review-body-holder .video a:hover:after {
  background-color: #fad379;
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .video a:hover img {
  opacity: 1;
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .video a:after {
  content: "";
  display: inline-block;
  background-image: url(../img/play.svg);
  background-position: 55% 50%;
  background-size: 12px 20px;
  background-repeat: no-repeat;
  background-clip: border-box;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: #F7B316;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .video a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .gallery {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.review .review-holder .review-body .review-body-holder .gallery .image {
  width: 33.333%;
  float: left;
  box-sizing: border-box;
  padding: 0 5px;
}
.review .review-holder .review-body .review-body-holder .gallery .image a {
  display: inline-block;
  width: 100%;
  height: 85px;
  overflow: hidden;
  border-radius: 5px;
}
.review .review-holder .review-body .review-body-holder .gallery .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.review .review-holder .review-body .review-body-holder .gallery .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.review .review-holder .review-body .action {
  display: inline-block;
  width: 100%;
  margin: 20px 0 0;
}
.review .review-holder .review-body .action a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
}
.review .review-holder .review-body .action a:hover {
  color: #F7B316;
}

@media (max-width: 1499px) {
  .reviews-block {
    padding: 30px 0 37px;
  }
  .review .review-holder .review-body.with-video .review-body-holder .text {
    -webkit-line-clamp: 7;
    line-clamp: 7;
    height: 140px;
  }
  .review .review-holder .review-body.with-gallery .review-body-holder .text {
    -webkit-line-clamp: 9;
    line-clamp: 9;
    height: 178px;
  }
  .review .review-holder .review-body .review-body-holder .video {
    height: 145px;
  }
  .review .review-holder .review-body .review-body-holder .gallery .image a {
    height: 106px;
  }
}
@media (max-width: 1025px) {
  .reviews-block {
    padding: 39px 0 28px;
    overflow: hidden;
  }
  .reviews-block.margin-bottom {
    margin: 0 0 28px;
  }
  .reviews.list .reviews-holder .review {
    width: 50%;
  }
  .reviews.list .reviews-holder .review .review-holder {
    width: 100%;
  }
  .reviews .slick-slider .slick-list {
    overflow: visible;
  }
  .reviews .reviews-holder .review {
    width: 335px;
  }
  .reviews .reviews-holder .review .review-holder {
    width: 325px;
  }
  .review .review-holder {
    padding: 20px;
  }
  .review .review-holder .author .image {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }
  .review .review-holder .author .text {
    width: calc(100% - 45px);
  }
  .review .review-holder .author .text .name {
    font-size: 14px;
  }
  .review .review-holder .review-body.with-video .review-body-holder .text {
    -webkit-line-clamp: 6;
    line-clamp: 6;
    height: 100px;
  }
  .review .review-holder .review-body.with-gallery .review-body-holder .text {
    -webkit-line-clamp: 10;
    line-clamp: 10;
    height: 175px;
  }
  .review .review-holder .review-body .review-body-holder {
    height: 265px;
  }
  .review .review-holder .review-body .review-body-holder .text {
    font-size: 12px;
    margin: 0 0 17px;
  }
  .review .review-holder .review-body .review-body-holder .video {
    height: 150px;
  }
  .review .review-holder .review-body .review-body-holder .gallery .image a {
    height: 72px;
  }
  .review .review-holder .review-body .action {
    margin: 13px 0 0;
  }
  .review .review-holder .review-body .action a {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .reviews-block .reviews-block-holder .common-action {
    margin: 23px 0 0;
  }
  .reviews.list .reviews-holder .review {
    width: 100%;
  }
  .reviews.list .reviews-holder .review .review-holder {
    width: 100%;
  }
  .reviews .reviews-holder .review {
    width: 270px;
  }
  .reviews .reviews-holder .review .review-holder {
    width: 260px;
  }
  .review .review-holder .author .text .name {
    margin: 0 0 4px;
  }
  .review .review-holder .author .text .stars .stars-rating .star svg {
    width: 12px;
    height: 12px;
  }
  .review .review-holder .review-body.with-video .review-body-holder .text {
    -webkit-line-clamp: 7;
    line-clamp: 7;
    height: 100px;
  }
  .review .review-holder .review-body.with-gallery .review-body-holder .text {
    -webkit-line-clamp: 14;
    line-clamp: 14;
    height: 200px;
  }
  .review .review-holder .review-body .review-body-holder {
    height: 270px;
  }
  .review .review-holder .review-body .review-body-holder .text {
    -webkit-line-clamp: 19;
    line-clamp: 19;
    font-size: 10px;
    margin: 0 0 14px;
  }
  .review .review-holder .review-body .review-body-holder .video {
    height: 155px;
  }
  .review .review-holder .review-body .review-body-holder .gallery .image a {
    height: 55px;
  }
  .review .review-holder .review-body .action a {
    font-size: 12px;
  }
}
.not-found-block {
  display: inline-block;
  width: 100%;
  padding: 141px 0 141px;
  overflow: hidden;
}
.not-found-block .not-found-block-holder {
  display: inline-block;
  width: 100%;
  position: relative;
}
.not-found-block .not-found-block-holder .text {
  display: inline-block;
  width: 518px;
}
.not-found-block .not-found-block-holder .text .text-holder {
  display: inline-block;
  width: 100%;
}
.not-found-block .not-found-block-holder .text .text-holder h1, .not-found-block .not-found-block-holder .text .text-holder .h1 {
  margin: 0 0 16px;
}
.not-found-block .not-found-block-holder .text .text-holder p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
  margin: 0 0 14px;
}
.not-found-block .not-found-block-holder .text .text-holder .actions {
  display: inline-block;
  width: 100%;
  margin: 24px 0 0;
}
.not-found-block .not-found-block-holder .text .text-holder .actions .action {
  display: inline-block;
  width: 100%;
  margin: 0 0 10px;
}
.not-found-block .not-found-block-holder .text .text-holder .actions .action a {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121214;
  display: inline-block;
  position: relative;
  padding-right: 26px;
}
.not-found-block .not-found-block-holder .text .text-holder .actions .action a:hover {
  color: #F7B316;
}
.not-found-block .not-found-block-holder .text .text-holder .actions .action a .icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 12px;
}
.not-found-block .not-found-block-holder .text .text-holder .actions .action a .icon svg {
  width: 100%;
  height: 100%;
}
.not-found-block .not-found-block-holder .image {
  position: absolute;
  left: 548px;
  top: -9px;
}
.not-found-block .not-found-block-holder .image .image-holder {
  width: 922px;
  height: 300px;
}
.not-found-block .not-found-block-holder .image .image-holder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1499px) {
  .not-found-block {
    padding: 97px 0 109px;
  }
  .not-found-block .not-found-block-holder .text {
    width: 372px;
  }
  .not-found-block .not-found-block-holder .text .text-holder h1, .not-found-block .not-found-block-holder .text .text-holder .h1 {
    line-height: 115%;
  }
  .not-found-block .not-found-block-holder .image {
    top: 59px;
    left: 402px;
  }
  .not-found-block .not-found-block-holder .image .image-holder {
    width: 708px;
    height: 230px;
  }
}
@media (max-width: 1025px) {
  .not-found-block {
    padding: 51px 0 66px;
  }
  .not-found-block .not-found-block-holder {
    padding: 213px 0 0;
  }
  .not-found-block .not-found-block-holder .text {
    display: block;
    width: 100%;
    text-align: center;
  }
  .not-found-block .not-found-block-holder .text .text-holder h1, .not-found-block .not-found-block-holder .text .text-holder .h1 {
    margin: 0 0 20px;
  }
  .not-found-block .not-found-block-holder .text .text-holder p {
    font-size: 14px;
  }
  .not-found-block .not-found-block-holder .text .text-holder .actions {
    margin: 18px 0 0;
  }
  .not-found-block .not-found-block-holder .text .text-holder .actions .action a {
    font-size: 14px;
  }
  .not-found-block .not-found-block-holder .image {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .not-found-block .not-found-block-holder .image .image-holder {
    width: 494px;
    height: 161px;
  }
}
@media (max-width: 767px) {
  .not-found-block {
    padding: 51px 0 66px;
  }
  .not-found-block .not-found-block-holder {
    padding: 144px 0 0;
  }
  .not-found-block .not-found-block-holder .text .text-holder h1, .not-found-block .not-found-block-holder .text .text-holder .h1 {
    margin: 0 0 20px;
  }
  .not-found-block .not-found-block-holder .text .text-holder p {
    font-size: 12px;
    margin: 0 0 16px;
  }
  .not-found-block .not-found-block-holder .text .text-holder .actions {
    margin: 13px 0 0;
  }
  .not-found-block .not-found-block-holder .text .text-holder .actions .action a {
    font-size: 12px;
  }
  .not-found-block .not-found-block-holder .image .image-holder {
    width: 290px;
    height: 94.3px;
  }
}
.quote-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 100px;
}
.quote-block .quote-block-holder {
  display: flex;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.quote-block .quote-block-holder .quote-text {
  width: calc(100% - 560px);
  height: auto;
  box-sizing: border-box;
  padding: 0 10px;
}
.quote-block .quote-block-holder .quote-text .quote-text-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 71px 30px 30px;
  background: #F9F9F9;
  border-radius: 10px;
  position: relative;
}
.quote-block .quote-block-holder .quote-text .quote-text-holder .quote-icon {
  position: absolute;
  left: 30px;
  top: 30px;
}
.quote-block .quote-block-holder .quote-text .quote-text-holder .text {
  display: inline-block;
  width: 100%;
}
.quote-block .quote-block-holder .quote-text .quote-text-holder .text p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
  margin: 0 0 14px;
}
.quote-block .quote-block-holder .quote-text .quote-text-holder .author {
  display: inline-block;
  width: 100%;
  margin: 9px 0 0;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.quote-block .quote-block-holder .quote-image {
  width: 560px;
  height: auto;
  box-sizing: border-box;
  padding: 0 10px;
}
.quote-block .quote-block-holder .quote-image .quote-image-holder {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.quote-block .quote-block-holder .quote-image .quote-image-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-info-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 46px;
}
.about-info-block .about-info-block-holder {
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.about-info-block .about-info-block-holder .info {
  width: calc(100% - 550px);
  box-sizing: border-box;
  padding: 0 15px;
}
.about-info-block .about-info-block-holder .info .info-holder {
  display: inline-block;
  width: 100%;
}
.about-info-block .about-info-block-holder .info .info-holder .text-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 55px;
}
.about-info-block .about-info-block-holder .info .info-holder .text-block .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.about-info-block .about-info-block-holder .info .info-holder .text-block .heading h3, .about-info-block .about-info-block-holder .info .info-holder .text-block .heading .h3 {
  margin: 0;
}
.about-info-block .about-info-block-holder .info .info-holder .text-block .text {
  display: inline-block;
  width: 100%;
}
.about-info-block .about-info-block-holder .info .info-holder .text-block .text p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
  margin: 0 0 14px;
}
.about-info-block .about-info-block-holder .image {
  width: 550px;
  box-sizing: border-box;
  padding: 0 15px;
}
.about-info-block .about-info-block-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.about-info-block .about-info-block-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  height: auto;
}

.numbers-block {
  display: inline-block;
  width: 100%;
}
.numbers-block .numbers-block-holder {
  display: inline-block;
  width: 100%;
}
.numbers-block .numbers-block-holder .heading {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 15px;
}
.numbers-block .numbers-block-holder .numbers {
  display: inline-block;
  width: 100%;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder {
  display: flex;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number {
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 10px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder {
  border-radius: 10px;
  background: #F9F9F9;
  padding: 22px 25px 25px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder .value {
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 4px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder .text {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 1499px) {
  .quote-block .quote-block-holder .quote-text {
    width: calc(100% - 471px);
  }
  .quote-block .quote-block-holder .quote-image {
    width: 471px;
  }
  .about-info-block {
    margin: 0 0 48px;
  }
  .about-info-block .about-info-block-holder .info {
    width: calc(100% - 481px);
  }
  .about-info-block .about-info-block-holder .info .info-holder .text-block {
    margin: 0 0 13px;
  }
  .about-info-block .about-info-block-holder .image {
    width: 481px;
  }
  .about-info-block .about-info-block-holder .image .image-holder {
    padding: 56px 0 0;
  }
  .numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder {
    padding: 23px 20px 23px;
  }
}
@media (max-width: 1025px) {
  .quote-block .quote-block-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .quote-block .quote-block-holder .quote-text {
    width: calc(100% - 335px);
    padding: 0 5px;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder {
    padding: 56px 20px 20px;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder .quote-icon {
    left: 20px;
    top: 20px;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder .text p {
    font-size: 14px;
    margin: 0 0 12px;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder .author {
    font-size: 14px;
  }
  .quote-block .quote-block-holder .quote-image {
    width: 335px;
    padding: 0 5px;
  }
  .about-info-block .about-info-block-holder {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }
  .about-info-block .about-info-block-holder .info {
    width: 100%;
    padding: 0;
  }
  .about-info-block .about-info-block-holder .info .info-holder {
    position: relative;
  }
  .about-info-block .about-info-block-holder .info .info-holder .text-block .heading h3, .about-info-block .about-info-block-holder .info .info-holder .text-block .heading .h3 {
    font-size: 22px;
  }
  .about-info-block .about-info-block-holder .info .info-holder .text-block .text p {
    font-size: 14px;
    margin: 0 0 17px;
  }
  .about-info-block .about-info-block-holder .info .info-holder .numbers-block {
    position: absolute;
    top: 100%;
    left: 412px;
    width: calc(95.203vw - 412px);
  }
  .about-info-block .about-info-block-holder .image {
    width: 100%;
    padding: 0;
  }
  .about-info-block .about-info-block-holder .image .image-holder {
    width: 392px;
    padding: 0;
  }
}
.numbers-block .numbers-block-holder .heading {
  font-size: 16px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder {
  flex-direction: column;
  width: 100%;
  margin: 0;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number {
  width: 100%;
  padding: 0;
  margin: 0 0 14px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder .value {
  font-size: 28px;
}
.numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder .text {
  font-size: 16px;
}

@media (max-width: 767px) {
  .quote-block {
    margin: 0 0 70px;
  }
  .quote-block .quote-block-holder {
    width: 100%;
    flex-wrap: wrap;
    margin: 0 0 10px;
  }
  .quote-block .quote-block-holder .quote-text {
    width: 100%;
    padding: 0;
    margin: 0 0 29px;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder {
    padding: 37px 0 0;
    background: none;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder .quote-icon {
    left: 0;
    top: 0;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder .text p {
    font-size: 12px;
  }
  .quote-block .quote-block-holder .quote-text .quote-text-holder .author {
    margin: 7px 0 0;
    font-size: 12px;
  }
  .quote-block .quote-block-holder .quote-image {
    width: 100%;
    padding: 0;
  }
  .about-info-block {
    margin: 0 0 28px;
  }
  .about-info-block .about-info-block-holder .info .info-holder .text-block .heading h3, .about-info-block .about-info-block-holder .info .info-holder .text-block .heading .h3 {
    font-size: 22px;
  }
  .about-info-block .about-info-block-holder .info .info-holder .text-block .text p {
    font-size: 12px;
    margin: 0 0 17px;
  }
  .about-info-block .about-info-block-holder .info .info-holder .numbers-block {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
  }
  .about-info-block .about-info-block-holder .image {
    margin: 0 0 19px;
  }
  .about-info-block .about-info-block-holder .image .image-holder {
    width: 100%;
  }
  .numbers-block .numbers-block-holder .heading {
    font-size: 14px;
  }
  .numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder {
    padding: 21px 20px 20px;
  }
  .numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder .value {
    font-size: 22px;
  }
  .numbers-block .numbers-block-holder .numbers .numbers-holder .number .number-holder .text {
    font-size: 14px;
  }
}
.common-actions {
  margin: 40px 0 0;
}
.common-actions .pagination-block {
  display: flex;
  justify-content: flex-end;
}

.certs-block {
  display: inline-block;
  width: 100%;
}
.certs-block .certs-block-holder {
  display: inline-block;
  width: 100%;
  padding: 50px 0 100px;
}

.certs-slider {
  display: inline-block;
  width: 100%;
  position: relative;
}
.certs-slider .prev, .certs-slider .next {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.certs-slider .prev.slick-arrow, .certs-slider .next.slick-arrow {
  opacity: 1;
  z-index: 1;
}
.certs-slider .certs-slider-holder {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.certs-slider .certs-slider-holder .cert-slide {
  width: 352px;
  box-sizing: border-box;
  padding: 0 10px;
}

.cert-slide .cert-slide-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  background: white;
  padding: 55px;
  box-sizing: border-box;
}
.cert-slide .cert-slide-holder a {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}
.cert-slide .cert-slide-holder a:hover img {
  opacity: 0.7;
  transition: all 300ms;
}
.cert-slide .cert-slide-holder a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 300ms;
}

@media (max-width: 1499px) {
  .certs-block .certs-block-holder {
    padding: 50px 0 80px;
  }
}
@media (max-width: 1025px) {
  .certs-block .certs-block-holder {
    padding: 50px 0 59px;
  }
  .certs-slider .certs-slider-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .certs-slider .certs-slider-holder .cert-slide {
    padding: 0 5px;
    width: 258px;
  }
  .cert-slide .cert-slide-holder {
    height: 300px;
    padding: 25px;
  }
}
@media (max-width: 767px) {
  .certs-block .certs-block-holder {
    padding: 40px 0 59px;
  }
  .certs-block .certs-block-holder .common-heading {
    margin: 0 0 41px;
  }
  .certs-slider .certs-slider-holder {
    width: calc(100% + 15px);
    margin-left: -7.5px;
  }
  .certs-slider .certs-slider-holder .cert-slide {
    padding: 0 7.5px;
    width: 153px;
  }
  .cert-slide .cert-slide-holder {
    height: 190px;
    padding: 15px;
  }
}
.contacts-block {
  display: inline-block;
  width: 100%;
}
.contacts-block .contacts-block-holder {
  display: inline-block;
  width: 100%;
}

.contacts-data {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
}
.contacts-data .contacts-data-holder {
  display: grid;
  grid-template-columns: 35.2% 1fr;
  grid-auto-columns: auto;
  gap: 20px 20px;
}
.contacts-data .contacts-data-holder .contacts-infoes {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 25px 30px;
  background-color: #F9F9F9;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading h2, .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading .h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains {
  display: inline-block;
  width: 100%;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line:last-of-type {
  margin: 0;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder {
  display: inline-block;
  width: 100%;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .name {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
  margin: 0 0 5px;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .value {
  display: inline-block;
  width: 101%;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .value a {
  color: #121214;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .value a:hover {
  color: #F7B316;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list {
  display: inline-block;
  width: 100%;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  margin: 0 0 8px;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item:last-of-type {
  margin: 0;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item .name {
  float: left;
  width: 51%;
}
.contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item .value {
  float: left;
  width: 49%;
}

.map-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 28px;
}
.map-block .map-block-holder {
  display: inline-block;
  width: 100%;
}
.map-block .map-block-holder .map-container {
  display: inline-block;
  width: 100%;
  height: 545px;
  border-radius: 10px;
  overflow: hidden;
}
.map-block .map-block-holder .map-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1499px) {
  .contacts-data .contacts-data-holder {
    grid-template-columns: 32.2% 1fr;
  }
}
@media (max-width: 1025px) {
  .contacts-data {
    margin: 0 0 10px;
  }
  .contacts-data .contacts-data-holder {
    grid-template-columns: 41% 1fr;
    gap: 10px 10px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder {
    padding: 25px 25px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading h2, .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading .h2 {
    font-size: 20px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .name {
    font-size: 14px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .value {
    font-size: 20px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item {
    font-size: 14px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item .name {
    width: 52%;
    box-sizing: border-box;
    padding-right: 10px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item .value {
    width: 48%;
  }
  .map-block {
    margin: 0 0 36px;
  }
}
@media (max-width: 767px) {
  .contacts-data {
    margin: 0 0 50px;
  }
  .contacts-data .contacts-data-holder {
    grid-template-columns: 1fr;
    gap: 50px 50px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder {
    padding: 0;
    background: none;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading {
    margin: 0 0 20px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading h2, .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .heading .h2 {
    font-size: 16px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line {
    margin: 0 0 20px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .name {
    font-size: 12px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .info-line .info-line-holder .value {
    font-size: 16px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item {
    font-size: 12px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item .name {
    width: 52%;
    box-sizing: border-box;
    padding-right: 10px;
  }
  .contacts-data .contacts-data-holder .contacts-infoes .contacts-infoes-holder .contains .reg-data-list .item .value {
    width: 48%;
  }
  .map-block {
    margin: 0 0 39px;
  }
  .map-block .map-block-holder .map-container {
    height: 108vw;
  }
}
.common-form {
  display: inline-block;
  width: 100%;
  background: #F9F9F9;
}
.common-form .common-form-holder {
  display: inline-block;
  width: 100%;
  padding: 60px 0 120px;
}
.common-form .common-form-holder .heading {
  float: left;
  width: 100%;
  margin: 0 0 50px;
}
.common-form .common-form-holder .heading .text {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.common-form .common-form-holder .heading .note {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px 0 0;
}
.common-form .common-form-holder .form {
  margin: 0 auto 0;
  width: 66.3%;
  position: relative;
}
.common-form .common-form-holder .form form .form-group {
  width: calc(100% + 15px);
  margin-left: -7.5px;
}
.common-form .common-form-holder .form form .form-group .form-col {
  padding: 0 7.5px;
}
.common-form .common-form-holder .form form .form-group .form-col.col-1 {
  width: calc((100% - 215px) / 2);
}
.common-form .common-form-holder .form form .form-group .form-col.col-2 {
  width: 215px;
}
.common-form .common-form-holder .form form .form-field .input input, .common-form .common-form-holder .form form .form-field .input textarea {
  background: white;
}
.common-form .common-form-holder .form form .action {
  margin: 0;
}
.common-form .common-form-holder .form form .action .agree-col {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin: 1px 0 0;
}

@media (max-width: 1499px) {
  .common-form .common-form-holder {
    padding: 50px 0 104px;
  }
  .common-form .common-form-holder .heading {
    margin: 0 0 53px;
  }
  .common-form .common-form-holder .form {
    width: 88%;
  }
  .common-form .common-form-holder .form form .action .agree-col {
    font-size: 14px;
    margin: 5px 0 0;
  }
}
@media (max-width: 1025px) {
  .common-form .common-form-holder {
    padding: 40px 0 84px;
  }
  .common-form .common-form-holder .heading {
    margin: 0 0 49px;
  }
  .common-form .common-form-holder .heading .text h3, .common-form .common-form-holder .heading .text .h3 {
    font-size: 22px;
  }
  .common-form .common-form-holder .heading .note {
    font-size: 16px;
  }
  .common-form .common-form-holder .form {
    width: 100%;
  }
  .common-form .common-form-holder .form form .action .agree-col {
    font-size: 12px;
    margin: 5px 0 0;
  }
}
@media (max-width: 767px) {
  .common-form .common-form-holder {
    padding: 40px 0 58px;
  }
  .common-form .common-form-holder .heading {
    margin: 0 0 42px;
  }
  .common-form .common-form-holder .heading .text h3, .common-form .common-form-holder .heading .text .h3 {
    font-size: 20px;
  }
  .common-form .common-form-holder .heading .note {
    font-size: 14px;
  }
  .common-form .common-form-holder .form {
    width: 100%;
  }
  .common-form .common-form-holder .form form .form-group {
    width: 100%;
    margin-left: 0;
  }
  .common-form .common-form-holder .form form .form-group .form-col {
    padding: 0;
  }
  .common-form .common-form-holder .form form .form-group .form-col.col-1 {
    width: 100%;
  }
  .common-form .common-form-holder .form form .form-group .form-col.col-2 {
    width: 100%;
  }
  .common-form .common-form-holder .form form .action .agree-col {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    font-size: 10px;
    margin: 15px 0 0;
  }
}
.policy-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 62px;
}
.policy-block .policy-block-holder {
  display: inline-block;
  width: 100%;
  color: #7A7A7A;
}
.policy-block .policy-block-holder p {
  margin: 0 0 17px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.policy-block .policy-block-holder ul {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}
.policy-block .policy-block-holder ul li {
  margin: 0 0 4px;
}
.policy-block .policy-block-holder ul li:before {
  content: "—";
  display: inline;
}

@media (max-width: 1025px) {
  .policy-block {
    margin: 0 0 40px;
  }
  .policy-block .policy-block-holder p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    margin: 0 0 20px;
  }
  .policy-block .policy-block-holder ul {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .policy-block .policy-block-holder ul li {
    margin: 0 0 1px;
  }
}
@media (max-width: 767px) {
  .policy-block {
    margin: 0 0 40px;
  }
  .policy-block .policy-block-holder p {
    font-size: 12px;
    margin: 0 0 18px;
  }
  .policy-block .policy-block-holder ul {
    font-size: 12px;
  }
  .policy-block .policy-block-holder ul li {
    margin: 0 0 1px;
  }
}
.promo-detail {
  display: inline-block;
  width: 100%;
  margin: 0 0 97px;
}
.promo-detail .promo-detail-holder {
  display: inline-block;
  width: 100%;
}
.promo-detail .promo-detail-holder .image {
  display: inline-block;
  width: 100%;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 18px;
}
.promo-detail .promo-detail-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.promo-detail .promo-detail-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promo-detail .promo-detail-holder .text {
  display: inline-block;
  width: 100%;
}
.promo-detail .promo-detail-holder .text .text-holder {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background: #F9F9F9;
  box-sizing: border-box;
  padding: 26px 30px 13px;
}
.promo-detail .promo-detail-holder .text .text-holder .inner-heading {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 29px;
}
.promo-detail .promo-detail-holder .text .text-holder .inner-heading .text {
  width: auto;
}
.promo-detail .promo-detail-holder .text .text-holder .inner-heading .text h3, .promo-detail .promo-detail-holder .text .text-holder .inner-heading .text .h3 {
  margin: 0;
}
.promo-detail .promo-detail-holder .text .text-holder .inner-heading .action {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 8px 0 0;
}
.promo-detail .promo-detail-holder .text .text-holder .inner-heading .action a {
  color: #121214;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: currentColor;
}
.promo-detail .promo-detail-holder .text .text-holder .inner-heading .action a:hover {
  color: #F7B316;
  text-decoration-color: transparent;
}
.promo-detail .promo-detail-holder .text .text-holder h3, .promo-detail .promo-detail-holder .text .text-holder .h3 {
  margin: 0 0 30px;
}
.promo-detail .promo-detail-holder .text .text-holder p {
  color: #7A7A7A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 14px;
}
.promo-detail .promo-detail-holder .text .text-holder ol, .promo-detail .promo-detail-holder .text .text-holder ul {
  padding-left: 54px;
  margin-bottom: 15px;
}
.promo-detail .promo-detail-holder .text .text-holder ol li, .promo-detail .promo-detail-holder .text .text-holder ul li {
  color: #7A7A7A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 4px;
}
@media (max-width: 1025px) {
  .promo-detail {
    margin: 0 0 101px;
  }
  .promo-detail .promo-detail-holder .image {
    height: 400px;
  }
  .promo-detail .promo-detail-holder .text .text-holder {
    padding: 26px 25px 9px;
  }
  .promo-detail .promo-detail-holder .text .text-holder .inner-heading .text h3, .promo-detail .promo-detail-holder .text .text-holder .inner-heading .text .h3 {
    font-size: 22px;
  }
  .promo-detail .promo-detail-holder .text .text-holder .inner-heading .action {
    font-size: 12px;
  }
  .promo-detail .promo-detail-holder .text .text-holder p {
    font-size: 14px;
    margin: 0 0 17px;
  }
  .promo-detail .promo-detail-holder .text .text-holder ol, .promo-detail .promo-detail-holder .text .text-holder ul {
    padding-left: 30px;
  }
  .promo-detail .promo-detail-holder .text .text-holder ol li, .promo-detail .promo-detail-holder .text .text-holder ul li {
    font-size: 14px;
    margin: 0 0 6px;
  }
}
@media (max-width: 767px) {
  .promo-detail {
    margin: 0 0 67px;
  }
  .promo-detail .promo-detail-holder .image {
    margin: 0 0 19px;
    height: 290px;
  }
  .promo-detail .promo-detail-holder .text .text-holder {
    padding: 0;
    background: none;
  }
  .promo-detail .promo-detail-holder .text .text-holder .inner-heading {
    flex-direction: column;
  }
  .promo-detail .promo-detail-holder .text .text-holder .inner-heading .text {
    width: 100%;
    margin: 0 0 3px;
  }
  .promo-detail .promo-detail-holder .text .text-holder .inner-heading .text h3, .promo-detail .promo-detail-holder .text .text-holder .inner-heading .text .h3 {
    font-size: 20px;
  }
  .promo-detail .promo-detail-holder .text .text-holder .inner-heading .action {
    width: 100%;
    font-size: 10px;
  }
  .promo-detail .promo-detail-holder .text .text-holder p {
    font-size: 12px;
    line-height: 143%;
    margin: 0 0 13px;
  }
  .promo-detail .promo-detail-holder .text .text-holder ol, .promo-detail .promo-detail-holder .text .text-holder ul {
    padding-left: 23px;
  }
  .promo-detail .promo-detail-holder .text .text-holder ol li, .promo-detail .promo-detail-holder .text .text-holder ul li {
    font-size: 12px;
    line-height: 143%;
    margin: 0 0 4px;
  }
}
.promos-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 97px;
}
.promos-block .promos-block-holder {
  display: inline-block;
  width: 100%;
}

.promos-list {
  display: inline-block;
  width: 100%;
}
.promos-list .promos-list-holder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 503px;
  gap: 20px 20px;
}

.promos-slider {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-bottom: 97px;
}
.promos-slider .promos-slider-holder .promo {
  box-sizing: border-box;
  padding: 0 10px;
  height: 503px;
}

.promo {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.promo .promo-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.promo .promo-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  border-radius: 10px;
  overflow: hidden;
}
.promo .promo-holder a:hover .text .desc {
  max-height: 500px;
  transition: all 1000ms;
}
.promo .promo-holder a .image {
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #121214;
}
.promo .promo-holder a .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
}
.promo .promo-holder a .text {
  display: inline-block;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 23px 25px;
}
.promo .promo-holder a .text .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  width: 100%;
  height: 66px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.promo .promo-holder a .text .desc {
  float: left;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 500ms;
}
.promo .promo-holder a .text .desc .desc-holder {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  line-clamp: 15;
  box-orient: vertical;
  width: 100%;
  margin: 15px 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media (max-width: 1499px) {
  .promos-block {
    margin: 0 0 79px;
  }
  .promos-list .promos-list-holder {
    grid-auto-rows: 390px;
  }
  .promos-slider .promos-slider-holder .promo {
    height: 390px;
  }
  .promo .promo-holder a .text .desc .desc-holder {
    -webkit-line-clamp: 12;
    line-clamp: 12;
  }
}
@media (max-width: 1025px) {
  .promos-block {
    margin: 0 0 58px;
  }
  .promos-list .promos-list-holder {
    grid-auto-rows: 290px;
    gap: 10px 10px;
  }
  .promos-slider {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-bottom: 57px;
  }
  .promos-slider .promos-slider-holder .promo {
    padding: 0 5px;
    height: 290px;
  }
  .promo .promo-holder a .image img {
    opacity: 0.6;
  }
  .promo .promo-holder a .text {
    padding: 20px 20px;
  }
  .promo .promo-holder a .text .name {
    font-size: 20px;
    height: 54px;
  }
  .promo .promo-holder a .text .desc {
    max-height: 500px;
  }
  .promo .promo-holder a .text .desc .desc-holder {
    -webkit-line-clamp: 9;
    line-clamp: 9;
    font-size: 14px;
    margin: 17px 0 0;
  }
}
@media (max-width: 767px) {
  .promos-list .promos-list-holder {
    gap: 15px 15px;
    grid-template-columns: 1fr;
    grid-auto-rows: 290px;
  }
  .promos-slider-block {
    overflow: hidden;
    margin: 0 0 60px;
  }
  .promos-slider-block .promos-slider-block-holder .common-heading {
    margin: 0 0 39px;
  }
  .promos-slider {
    width: 100%;
    margin-left: 0;
    margin-bottom: 8px;
  }
  .promos-slider .slick-slider .slick-list {
    overflow: visible;
  }
  .promos-slider .promos-slider-holder .promo {
    padding: 0;
    height: 290px;
    margin-right: 10px;
  }
  .promos-slider .promos-slider-holder .promo .promo-holder {
    width: 260px;
  }
  .promo .promo-holder a .text .name {
    font-size: 16px;
    height: 44px;
  }
  .promo .promo-holder a .text .desc {
    max-height: 500px;
  }
  .promo .promo-holder a .text .desc .desc-holder {
    -webkit-line-clamp: 11;
    line-clamp: 11;
    font-size: 12px;
    margin: 17px 0 0;
  }
}
.review-form-block {
  display: inline-block;
  width: 100%;
}
.review-form-block .review-form-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 60px 8.5% 100px;
}

.review-form {
  display: inline-block;
  width: 100%;
  background: #F9F9F9;
  box-sizing: border-box;
  padding: 60px 9.7% 77px;
}
.review-form .review-form-holder {
  display: inline-block;
  width: 100%;
}
.review-form .review-form-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 29px;
}
.review-form .review-form-holder .heading h2, .review-form .review-form-holder .heading .h2 {
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.review-form .review-form-holder form {
  display: inline-block;
  width: 100%;
  position: relative;
}
.review-form .review-form-holder form .col {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0 7.5px;
}
.review-form .review-form-holder form .col .col-holder {
  display: inline-block;
  width: 100%;
}
.review-form .review-form-holder form .form-field label.input .addon {
  top: 19px;
  transform: none;
}
.review-form .review-form-holder form .form-field label.input input, .review-form .review-form-holder form .form-field label.input textarea {
  background: white;
}
.review-form .review-form-holder form .form-field label.input textarea {
  padding: 15px 10px 0 48px;
  height: 150px;
}
.review-form .review-form-holder form .form-field label.file-upload .btn {
  margin: 0;
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px dashed #EDEDED;
  color: #7A7A7A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.review-form .review-form-holder form .form-field label.file-upload .btn .icon {
  margin-right: 5px;
}
.review-form .review-form-holder form .form-field label.file-upload .btn .icon svg {
  width: 18px;
  height: 18px;
}
.review-form .review-form-holder form .action {
  margin: 0;
}
.review-form .review-form-holder form .action .agree-col {
  margin: -1px 0 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1499px) {
  .review-form-block .review-form-block-holder {
    padding: 60px 0 82px;
  }
  .review-form {
    padding: 60px 5.6% 77px;
  }
  .review-form .review-form-holder form .form-field {
    margin-bottom: 12px;
  }
  .review-form .review-form-holder form .action .agree-col {
    margin: 1px 0 0;
    font-size: 14px;
  }
}
@media (max-width: 1025px) {
  .review-form-block .review-form-block-holder {
    padding: 48px 0 60px;
  }
  .review-form {
    padding: 40px 5.6% 66px;
  }
  .review-form .review-form-holder .heading {
    margin: 0 0 30px;
  }
  .review-form .review-form-holder .heading h2, .review-form .review-form-holder .heading .h2 {
    font-size: 28px;
  }
  .review-form .review-form-holder form .form-field label.input .addon {
    top: 14px;
  }
  .review-form .review-form-holder form .form-field label.input textarea {
    padding: 11px 10px 0 48px;
    height: 160px;
  }
  .review-form .review-form-holder form .form-field label.file-upload .btn {
    font-size: 14px;
  }
  .review-form .review-form-holder form .action .agree-col {
    margin: 13px 0 0;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .review-form {
    padding: 20px 20px 56px;
  }
  .review-form .review-form-holder .heading {
    margin: 0 0 26px;
  }
  .review-form .review-form-holder .heading h2, .review-form .review-form-holder .heading .h2 {
    font-size: 22px;
  }
  .review-form .review-form-holder form .col {
    width: 100%;
    padding: 0;
  }
  .review-form .review-form-holder form .form-field {
    margin-bottom: 9px;
  }
  .review-form .review-form-holder form .form-field label.input textarea {
    padding: 11px 10px 0 43px;
    height: 147px;
  }
  .review-form .review-form-holder form .form-field label.file-upload .btn {
    font-size: 12px;
    height: 40px;
  }
  .review-form .review-form-holder form .action .agree-col {
    margin: 10px 0 0;
    font-size: 10px;
  }
}
.text-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 87px;
}
.text-block .text-block-holder {
  display: inline-block;
  width: 100%;
}
.text-block .text-block-holder h2, .text-block .text-block-holder .h2 {
  margin: 0 0 31px;
}
.text-block .text-block-holder p {
  font-size: 16px;
  margin: 0 0 13px;
}

@media (max-width: 1499px) {
  .text-block {
    margin: 0 0 65px;
  }
}
@media (max-width: 1025px) {
  .text-block {
    margin: 0 0 44px;
  }
  .text-block .text-block-holder p {
    font-size: 14px;
    margin: 0 0 15px;
    line-height: 143%;
  }
}
@media (max-width: 767px) {
  .text-block .text-block-holder p {
    font-size: 12px;
    margin: 0 0 15px;
    line-height: 142%;
  }
}