@media (max-width: $screen-sm) {
    // sm-view
    .review-form {
        padding: 20px 20px 56px;
        .review-form-holder { 
            .heading { 
                margin: 0 0 26px;
                h2, .h2 {
                    font-size: 22px;
                }
            }

            form { 
                .col { 
                    width: 100%;
                    padding: 0;
                    .col-holder { 

                    }
                }

                .form-field { 
                    margin-bottom: 9px;
                    label.input { 
                        textarea {
                            padding: 11px 10px 0 43px;
                            height: 147px;
                        }
                    }

                    label.file-upload {
                        .btn {
                            font-size: 12px;
                            height: 40px;
                        }
                    }
                }

                .action {
                    .agree-col {
                        margin: 10px 0 0;
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
