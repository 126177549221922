@media (max-width: $screen-sm) {
    // sm-view
    .certs-block {
        .certs-block-holder {
            padding: 40px 0 59px;
            .common-heading {
                margin: 0 0 41px;
            }
        }
    }

    .certs-slider {
        .certs-slider-holder {
            width: calc(100% + 15px);
            margin-left: -7.5px;
            .cert-slide {
                padding: 0 7.5px;
                width: 153px;
            }
        }
    }

    .cert-slide {
        .cert-slide-holder {
            height: 190px;
            padding: 15px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
