@media (max-width: $screen-sm) {
    // sm-view
    .promo-detail { 
        margin: 0 0 67px;
        .promo-detail-holder { 
            .image { 
                margin: 0 0 19px;
                height: 290px;
            }

            .text { 
                .text-holder { 
                    padding: 0;
                    background: none;
                    .inner-heading {
                        flex-direction: column;
                        .text { 
                            width: 100%;
                            margin: 0 0 3px;
                            h3, .h3 {
                                font-size: 20px;
                            }
                        }

                        .action { 
                            width: 100%;
                            font-size: 10px;
                        }
                    }

                    h3, .h3 {

                    }

                    p {
                        font-size: 12px;
                        line-height: 143%;
                        margin: 0 0 13px;
                    }

                    ol, ul { 
                        padding-left: 23px;
                        li {
                            font-size: 12px;
                            line-height: 143%;
                            margin: 0 0 4px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
