@media (max-width: $screen-lg) {
    // lg-md view
    .reviews-block {
        padding: 30px 0 37px;
    }

    .reviews { 
        .reviews-holder { 
           
        }
    }

    .review { 
        .review-holder { 

            .review-body { 
                &.with-video {
                    .review-body-holder { 
                        .text {
                            -webkit-line-clamp: 7;
                            line-clamp: 7;
                            height: 140px;
                        }
                    }
                }
                &.with-gallery {
                    .review-body-holder { 
                        .text {
                            -webkit-line-clamp: 9;
                            line-clamp: 9;
                            height: 178px;
                        }
                    }
                }
                .review-body-holder { 
                    .text {

                    }

                    .video { 
                        height: 145px;
                    }

                    .gallery { 
                        .image {
                            a {
                                height: 106px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .reviews-block {
        padding: 39px 0 28px;
        overflow: hidden;
        &.margin-bottom {
            margin: 0 0 28px;
        }
    }

    .reviews.list {
        .reviews-holder { 
            .review { 
                width: 50%;
                .review-holder { 
                    width: 100%;
                }
            }
        }
    }

    .reviews { 

        .slick-slider {
            .slick-list {
                overflow: visible;
            }
        }

        .reviews-holder { 
            .review { 
                width: 335px;
                .review-holder { 
                    width: 325px;
                }
            }
        }
    }

    .review { 
        .review-holder { 
            padding: 20px;
            .author { 
                .image { 
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    font-size: 20px;
                }

                .text { 
                    width: calc(100% - 45px);
                    .name {
                        font-size: 14px;
                    }
                }
            }

            .review-body { 
                &.with-video {
                    .review-body-holder { 
                        .text {
                            -webkit-line-clamp: 6;
                            line-clamp: 6;
                            height: 100px;
                        }
                    }
                }
                &.with-gallery {
                    .review-body-holder { 
                        .text {
                            -webkit-line-clamp: 10;
                            line-clamp: 10;
                            height: 175px;
                        }
                    }
                }
                .review-body-holder { 
                    height: 265px;
                    .text {
                        font-size: 12px;
                        margin: 0 0 17px;

                    }

                    .video { 
                        height: 150px;
                    }

                    .gallery {
                        .image { 
                            a {
                                height: 72px;
                            }
                        }
                    }
                }

                .action { 
                    margin: 13px 0 0;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
