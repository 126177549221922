@media (max-width: $screen-lg) {
    // lg-md view
    .contacts-data { 
        .contacts-data-holder { 
            grid-template-columns: 32.2% 1fr;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .contacts-data { 
        margin: 0 0 10px;
        .contacts-data-holder { 
            grid-template-columns: 41% 1fr;
            gap: 10px 10px;
            .contacts-infoes { 
                .contacts-infoes-holder { 
                    padding: 25px 25px;
                    .heading { 
                        h2, .h2 {
                            font-size: 20px;
                        }
                    }

                    .contains { 
                        .info-line { 
                            .info-line-holder { 
                                .name {
                                    font-size: 14px;
                                }
                                
                                .value { 
                                    font-size: 20px;
                                }
                            }
                        }

                        .reg-data-list { 
                            .item { 
                                font-size: 14px;
                                .name {
                                    width: 52%;
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                }

                                .value {    
                                    width: 48%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .map-block {
        margin: 0 0 36px;
    }
}
