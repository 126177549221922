.promo-detail { 
    display: inline-block;
    width: 100%;
    margin: 0 0 97px;
	.promo-detail-holder { 
        display: inline-block;
        width: 100%;
		.image { 
            display: inline-block;
            width: 100%;
            height: 600px;
            border-radius: 10px;
            overflow: hidden;
            margin: 0 0 18px;
			.image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}
		}

		.text { 
            display: inline-block;
            width: 100%;
			.text-holder { 
                display: inline-block;
                width: 100%;
                border-radius: 10px;
                background: $bg-secondary;
                box-sizing: border-box;
                padding: 26px 30px 13px;

                .inner-heading { 
                    display: flex;
                    width: 100%;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin: 0 0 29px;
                    .text { 
                        width: auto;
                        h3, .h3 { 
                            margin: 0;
                        }
                    }
                
                    .action { 
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        padding: 8px 0 0;
                        a { 
                            color: $gray-base;
                            text-decoration: underline;
                            text-decoration-style: dotted;
                            text-decoration-color: currentColor;
                            &:hover {
                                color: $brand-primary;
                                text-decoration-color: transparent;
                            }
                        }
                    }
                }

				h3, .h3 { 
                    margin: 0 0 30px;
				}

				p { 
                    color: $gray-dark;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; 
                    margin: 0 0 14px;
				}

				ol, ul { 
                    padding-left: 54px;
                    margin-bottom: 15px;
					li { 
                        color: $gray-dark;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; 
                        margin: 0 0 4px;
					}
				}

				ul { 

					li { 

					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/promo_detail";
@import "../../media/mobile/includes/common/promo_detail";
