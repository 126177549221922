.review-form-block {
    display: inline-block;
    width: 100%;
    .review-form-block-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 60px 8.5% 100px;
    }
}

.review-form { 
    display: inline-block;
    width: 100%;
    background: $bg-secondary;
    box-sizing: border-box;
    padding: 60px 9.7% 77px;
	.review-form-holder { 
        display: inline-block;
        width: 100%;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 29px;
			h2, .h2 { 
                text-align: center;
                font-size: 38px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
			}
		}

		form { 
            display: inline-block;
            width: 100%;
            position: relative;
			.col { 
                float: left;
                width: 50%;
                box-sizing: border-box;
                padding: 0 7.5px;
				.col-holder { 
                    display: inline-block;
                    width: 100%;
				}
			}

            .form-group { 

                .form-col { 

                    
                }
            }

            .form-field { 

                label.input { 

                    .name { 

                    }

                    .addon { 
                        top: 19px;
                        transform: none;
                        svg { 

                            path { 

                            }
                        }
                    }

                    input, textarea { 
                        background: white;
                    }

                    textarea {
                        padding: 15px 10px 0 48px;
                        height: 150px;
                    }
                }

                label.file-upload {
                    .btn {
                        margin: 0;
                        width: 100%;
                        height: 220px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        border: 1px dashed #EDEDED;
                        color: $gray-dark;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        .icon {
                            margin-right: 5px;
                            svg {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
            
            .action {
                margin: 0;
                .agree-col {
                    margin: -1px 0 0;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
		}
	}
}

@import "../../media/tablet/includes/common/review_form";
@import "../../media/mobile/includes/common/review_form";
