@media (max-width: $screen-lg) {
    // lg-md view
    .not-found-block { 
        padding: 97px 0 109px;
        .not-found-block-holder { 
            .text { 
                width: 372px;
                .text-holder { 
                    h1, .h1 { 
                        line-height: 115%;
                    }
                }
            }

            .image { 
                top: 59px;
                left: 402px;
                .image-holder { 
                    width: 708px;
                    height: 230px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .not-found-block { 
        padding: 51px 0 66px;
        .not-found-block-holder {
            padding: 213px 0 0;
            .text { 
                display: block;
                width: 100%;
                text-align: center;
                .text-holder { 
                    h1, .h1 { 
                        margin: 0 0 20px;
                    }

                    p {
                        font-size: 14px;
                    }

                    .actions {
                        margin: 18px 0 0;
                        .action { 
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .image { 
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                .image-holder { 
                    width: 494px;
                    height: 161px;
                }
            }
        }
    }
}
