@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .promo-detail { 
        margin: 0 0 101px;
        .promo-detail-holder { 
            .image { 
                height: 400px;
            }

            .text { 
                .text-holder { 
                    padding: 26px 25px 9px;
                    .inner-heading { 
                        .text { 
                            h3, .h3 {
                                font-size: 22px;
                            }
                        }

                        .action { 
                            font-size: 12px;
                        }
                    }

                    h3, .h3 {

                    }

                    p {
                        font-size: 14px;
                        margin: 0 0 17px;
                    }

                    ol, ul { 
                        padding-left: 30px;
                        li {
                            font-size: 14px;
                            margin: 0 0 6px;
                        }
                    }

                    ul {
                        li {

                        }
                    }
                }
            }
        }
    }
}
