@media (max-width: $screen-sm) {
    // sm-view
    .contacts-data { 
        margin: 0 0 50px;
        .contacts-data-holder { 
            grid-template-columns: 1fr;
            gap: 50px 50px;
            .contacts-infoes { 
                .contacts-infoes-holder { 
                    padding: 0;
                    background: none;
                    .heading { 
                        margin: 0 0 20px;
                        h2, .h2 {
                            font-size: 16px;
                        }
                    }

                    .contains { 
                        .info-line { 
                            margin: 0 0 20px;
                            .info-line-holder { 
                                .name {
                                    font-size: 12px;
                                }
                                
                                .value { 
                                    font-size: 16px;
                                }
                            }
                        }

                        .reg-data-list { 
                            .item { 
                                font-size: 12px;
                                .name {
                                    width: 52%;
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                }

                                .value {    
                                    width: 48%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .map-block {
        margin: 0 0 39px;
        .map-block-holder {
            .map-container {
                height: 108vw;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
