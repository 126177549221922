.contacts-block {
    display: inline-block;
    width: 100%;
    .contacts-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.contacts-data { 
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
	.contacts-data-holder { 
        display: grid;
        grid-template-columns: 35.2% 1fr;
        grid-auto-columns: auto;
        gap: 20px 20px;
		.contacts-infoes { 
            display: inline-block;
            width: 100%;
            height: 100%;
			.contacts-infoes-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 25px 30px;
                background-color: $bg-secondary;
				.heading { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 30px;
					h2, .h2 { 
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0;
					}
				}

				.contains { 
                    display: inline-block;
                    width: 100%;
					.info-line { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 25px;
                        &:last-of-type {
                            margin: 0;
                        }
						.info-line-holder { 
                            display: inline-block;
                            width: 100%;
							.name { 
                                display: inline-block;
                                width: 100%;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                                color: $gray-dark;
                                margin: 0 0 5px;
							}

							.value { 
                                display: inline-block;
                                width: 101%;
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
								a { 
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
								}
							}
						}
					}

                    .reg-data-list { 
                        display: inline-block;
                        width: 100%;
                        .item { 
                            display: inline-block;
                            width: 100%;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 143%;
                            margin: 0 0 8px;
                            &:last-of-type {
                                margin: 0;
                            }
                            .name { 
                                float: left;
                                width: 51%;
                            }
                    
                            .value { 
                                float: left;
                                width: 49%;
                            }
                        }
                    }
				}
			}
		}
	}
}

.map-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 28px;
    .map-block-holder {
        display: inline-block;
        width: 100%;
        .map-container {
            display: inline-block;
            width: 100%;
            height: 545px;
            border-radius: 10px;
            overflow: hidden;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@import "../../media/tablet/includes/common/contacts";
@import "../../media/mobile/includes/common/contacts";
