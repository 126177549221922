@media (max-width: $screen-sm) {
    // sm-view
    .reviews-block {
        .reviews-block-holder {
            .common-action {
                margin: 23px 0 0;
            }
        }
    }

    .reviews.list {
        .reviews-holder { 
            .review { 
                width: 100%;
                .review-holder { 
                    width: 100%;
                }
            }
        }
    }

    .reviews {
        .reviews-holder { 
            .review { 
                width: 270px;
                .review-holder { 
                    width: 260px;
                }
            } 
        }
    }

    .review { 
        .review-holder { 
            .author { 
                .image { 

                }

                .text {
                    .name {
                        margin: 0 0 4px;
                    }

                    .stars {
                        .stars-rating { 
                            .star { 
                                svg {
                                    width: 12px;
                                    height: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .review-body { 
                &.with-video {
                    .review-body-holder { 
                        .text {
                            -webkit-line-clamp: 7;
                            line-clamp: 7;
                            height: 100px;
                        }
                    }
                }
                &.with-gallery {
                    .review-body-holder { 
                        .text {
                            -webkit-line-clamp: 14;
                            line-clamp: 14;
                            height: 200px;
                        }
                    }
                }
                .review-body-holder { 
                    height: 270px;
                    .text {
                        -webkit-line-clamp: 19;
                        line-clamp: 19;
                        font-size: 10px;
                        margin: 0 0 14px;
                    }

                    .video { 
                        height: 155px;
                        a {

                        }
                    }

                    .gallery { 
                        .image { 
                            a {
                                height: 55px;
                            }
                        }
                    }
                }  

                .action { 
                    a {
                        font-size: 12px;
                    }
                }
            }
        }
    } 
}

@media (max-width: $screen-xs) {
    // xs-view
}
