@media (max-width: $screen-sm) {
    // sm-view
    .policy-block { 
        margin: 0 0 40px;
        .policy-block-holder { 
            p {
                font-size: 12px;
                margin: 0 0 18px;
            }

            ul { 
                font-size: 12px;
                li {
                    margin: 0 0 1px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
