@media (max-width: $screen-sm) {
    // sm-view
    .quote-block { 
        margin: 0 0 70px;
        .quote-block-holder {
            width: 100%;
            flex-wrap: wrap;
            margin: 0 0 10px;
            .quote-text { 
                width: 100%;
                padding: 0;
                margin: 0 0 29px;
                .quote-text-holder { 
                    padding: 37px 0 0;
                    background: none;
                    .quote-icon { 
                        left: 0;
                        top: 0;
                    }

                    .text { 
                        p {
                            font-size: 12px;
                        }
                    }

                    .author { 
                        margin: 7px 0 0;
                        font-size: 12px;
                    }
                }
            }

            .quote-image { 
                width: 100%;
                padding: 0;
            }
        }
    }

    .about-info-block { 
        margin: 0 0 28px;
        .about-info-block-holder { 
            .info { 
                .info-holder { 
                    .text-block { 
                        .heading { 
                            h3, .h3 {
                                font-size: 22px;
                            }
                        }
                        .text {
                            p {
                                font-size: 12px;
                                margin: 0 0 17px;
                            }
                        }
                    }

                    .numbers-block { 
                        position: relative;
                        top: auto;
                        left: auto;
                        width: 100%;
                    }
                }
            }

            .image { 
                margin: 0 0 19px;
                .image-holder { 
                    width: 100%;
                }
            }
        }
    }

    .numbers-block { 
        .numbers-block-holder { 
            .heading { 
                font-size: 14px;
            }

            .numbers { 
                .numbers-holder {
                    .number { 
                        .number-holder { 
                            padding: 21px 20px 20px;
                            .value {
                                font-size: 22px;
                            }
    
                            .text {
                                font-size: 14px;
                            }
                        }
                    } 
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
