@media (max-width: $screen-sm) {
    // sm-view
    .promos-list {
        .promos-list-holder {
            gap: 15px 15px;
            grid-template-columns: 1fr;
            grid-auto-rows: 290px;
        }
    }

    .promos-slider-block {
        overflow: hidden;
        margin: 0 0 60px;
        .promos-slider-block-holder {
            .common-heading {
                margin: 0 0 39px;
            }
        }
    }

    .promos-slider {
        width: 100%;
        margin-left: 0;
        margin-bottom: 8px;
        .slick-slider {
            .slick-list {
                overflow: visible;
            }
        }
        .promos-slider-holder {
            .promo {
                padding: 0;
                height: 290px;
                margin-right: 10px;
                .promo-holder {
                    width: 260px;
                }

            }
        }
    }

    .promo { 
        .promo-holder { 
            a {
                .image { 
                    img { 
                        
                    }
                }

                .text { 
                    .name {
                        font-size: 16px;
                        height: 44px;
                    }
                    .desc { 
                        max-height: 500px;
                        .desc-holder { 
                            -webkit-line-clamp: 11;
                            line-clamp: 11;
                            font-size: 12px;
                            margin: 17px 0 0;
                            
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
