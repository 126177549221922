.policy-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 62px;
	.policy-block-holder { 
        display: inline-block;
        width: 100%;
        color: #7A7A7A;
		p { 
            margin: 0 0 17px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%
		}

		ul { 
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            list-style: none;
            padding: 0;
            margin: 0 0 20px;
			li { 
                &:before {
                    content: "—";
                    display: inline;
                }
                margin: 0 0 4px;
			}
		}
	}
}

@import "../../media/tablet/includes/common/policy";
@import "../../media/mobile/includes/common/policy";
